import React, { useEffect, useState } from "react";

import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import clsx from "clsx";
import Swal from "sweetalert2";

import PrivateMiducRoute from "~/src/components/Authentication/MiducPrivateRoute";
import TokenValidator from "~/src/components/Authentication/MiducTokenValidator";
import CouponForm from "~/src/components/coupons/CouponForm";
import Layout from "~/src/components/Layout";
import SEO from "~/src/components/SEO";
import { Country } from "~/src/constants/countries";
import { LabService, SelectedLabService } from "~/src/types/lab-services";
import { fetchLabServices } from "~/src/utils/api-lab-services";
import namespaced from "~/src/utils/debug";

const debug = namespaced("coupons/new");
// Components

const NewCoupon = (): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [allLabServices, setAllLabServices] = useState<LabService[]>([]);
  const [labServicesHash, setLabServicesHash] = useState<{ [key: string]: SelectedLabService }>({});
  const [displayedLabServices, setDisplayedLabServices] = useState<LabService[] | undefined>([]);
  const [country, setCountry] = useState<Country | null>(Country.CHILE);

  const fetchData = async () => {
    setLoading(true);
    try {
      const bloodLabServicesData = (await fetchLabServices({ category: "blood", country, query: null })) || [];
      const covidLabServicesData = (await fetchLabServices({ category: "covid", country, query: null })) || [];
      const urineLabServicesData = (await fetchLabServices({ category: "urine", country, query: null })) || [];
      const vaccinesLabSerivcesData = (await fetchLabServices({ category: "vaccine", country, query: null })) || [];
      const allServicesData = [
        ...bloodLabServicesData,
        ...covidLabServicesData,
        ...urineLabServicesData,
        ...vaccinesLabSerivcesData,
      ];
      setAllLabServices(allServicesData);
      setLabServicesHash(
        allServicesData.reduce((acc: { [key: string]: SelectedLabService }, curr: SelectedLabService) => {
          acc[curr.id] = {
            ...curr,
            selected: false,
          };
          return acc;
        }, {}),
      );
      setDisplayedLabServices(allServicesData);
    } catch (err) {
      await Swal.fire({
        title: "Error",
        text: "Something went wrong",
        icon: "error",
        confirmButtonText: "Ok",
      });
      debug(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  return (
    <Layout>
      {/* @ts-expect-error TokenValidator cannot be used as a JSX element */}
      <TokenValidator>
        {/* @ts-expect-error PrivateRoute cannot be used as a JSX element */}
        <PrivateMiducRoute>
          <SEO title="Examedi Marketing Crear Cupón" />

          <div className={clsx("min-h-screen w-full bg-gray-100 relative overflow-y-auto p-4")}>
            <div className={clsx("p-10 max-w-2xl mx-auto bg-white border border-gray-300 rounded-md")}>
              <div className={clsx("mb-8")}>
                <h1 className={clsx("mr-8 text-4xl")}>Crear cupón</h1>
              </div>
              <div className={clsx("w-full", "mb-12", "flex flex-col")}>
                <p className={clsx("mb-2", "text-lg")}>Elige el país donde quieres que el cupon tenga efecto</p>
                <Select value={country} onChange={(e) => setCountry(e.target.value as Country)} fullWidth>
                  {Object.entries(Country).map(([key, value]) => (
                    <MenuItem key={key} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className="w-full mb-12 flex flex-col">
                <p className="mb-2 text-lg mx-auto">Datos del cupón</p>
                <CouponForm
                  allLabServices={allLabServices}
                  labServicesHash={labServicesHash}
                  loading={loading}
                  displayedLabServices={displayedLabServices}
                  newCoupon
                  country={country || undefined}
                  setAllLabServices={setAllLabServices}
                  setLabServicesHash={setLabServicesHash}
                  setLoading={setLoading}
                  setDisplayedLabServices={setDisplayedLabServices}
                />
              </div>
            </div>
          </div>
        </PrivateMiducRoute>
      </TokenValidator>
    </Layout>
  );
};

export default NewCoupon;
